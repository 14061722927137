import nextDynamic, { DynamicOptions, Loader } from 'next/dynamic';

import LoadingSpinner from '~/components/loading-spinner/loading-spinner.component';

function dynamic<P = unknown>(
  loader: DynamicOptions<P> | Loader<P>,
  dynamicOptions?: DynamicOptions<P>
) {
  return nextDynamic(loader, {
    loading: () => <LoadingSpinner />,
    ...dynamicOptions,
  });
}

export default dynamic;
