import { useQuery } from '@tanstack/react-query';

import ReactQueryKeys from '../keys';
import { apiV1, get } from '../utils';
import { WalletData } from '~/types/wallet.types';
import useStore from '~/store';

const getMyWallet = get<WalletData>(apiV1, '/wallets/me');

const useGetMyWallet = () => {
  const currentUser = useStore((state) => state.currentUser);

  return useQuery({
    queryFn: () => getMyWallet(),
    queryKey: [ReactQueryKeys.GET_MY_WALLET, currentUser?.id],
    staleTime: 1000,
    keepPreviousData: true,
  });
};

export default useGetMyWallet;
