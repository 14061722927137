import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingSpinner: FC = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

const rotate = keyframes`
100% {
      transform: rotate(1turn);
  }
`;

const Spinner = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: radial-gradient(
        farthest-side,
        rgb(var(--color-neutral5)) 94%,
        #0000
      )
      top/8px 8px no-repeat,
    conic-gradient(#0000 30%, rgb(var(--color-neutral5)));
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: ${rotate} 1s infinite linear;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem !important;
`;

export default LoadingSpinner;
