import styled from 'styled-components';

import { SidebarDetail } from '@gate-academy/react';
import { getOffset } from '@gate-academy/shared/lib';

import useStore from '~/store';

import DynamicUpdateTimezone from '../update-timezone/dynamic-update-timezone.component';

const UserTimeInfoStyle = styled.div`
  color: rgb(var(--color-error));
  font-size: 1.4rem;
  display: inline;

  & > button {
    text-decoration: underline;
    color: inherit;
    background: none;
    font: inherit;
    font-weight: 700;
    border: none;
  }
`;

const UserTimezoneInfo = () => {
  const { currentUser } = useStore();

  return (
    <UserTimeInfoStyle>
      Note: All time information is in:{' '}
      <SidebarDetail
        heading="Update your timezone"
        trigger={
          <button type="button">
            {`${currentUser?.timezone} (${getOffset(
              currentUser?.timezone ?? ''
            )})`}
          </button>
        }
      >
        {(close) => <DynamicUpdateTimezone closeSidebar={close} />}
      </SidebarDetail>
    </UserTimeInfoStyle>
  );
};

export default UserTimezoneInfo;
